footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #DADADA;
}

.barra-navegacion{
    background-color: #ad3333;
}
.form_group_recaptcha{
    display: flex;
    place-content: center;
}
